<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
    >
      <p>Technical Support</p>
      <p>
        If you have any questions, please contact us: <a
          href="mailto:service@netboom.com"
          target="_blank"
          class="url"
          style="
    color: #990000;
    text-decoration: none;
  "
        >NetboomGame@netboom.com</a>
      </p>
      <p> Thanks for support</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArabicPrivacy',
  metaInfo: {
    title: 'Privacy Policy',
    link: []
  }
}
</script>

<style lang="less">
  @import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
  .wrapper{
    padding: 0 5%;
    background:#F0F0F0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    min-height: 100%;
    box-sizing: border-box;
  }
</style>
